export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyC615uL_nOXyDdWA3TjbZw9zR4KkhfSaCc',
    appId: '1:231099558034:web:74db13416882dd79369e4b',
    authDomain: 'geostavwebsite.firebaseapp.com',
    measurementId: "G-YE361F53FJ",
    messagingSenderId: '231099558034',
    projectId: 'geostavwebsite',
    storageBucket: 'geostavwebsite.appspot.com',
    locationId: 'europe-central2',
  },
  debug: true,
};
